/*===================== Typography =====================*/
/*===================== Color =====================*/
/* Product card configuration */
/* Wishlist & Compare configuration */
/* Footer configuration */
/* User Notice Messages */
.nav-to-tab-desc {
  margin-bottom: 5rem;
}

.template-product {
  overflow: unset;
}

.product-details-tabs ol:not(.list-style-none),
.product-details-tabs ul:not(.list-style-none),
.products-template ol:not(.list-style-none),
.products-template ul:not(.list-style-none) {
  padding-bottom: 1.5em;
  padding-left: 2em;
}
.products-template {
  background:#fff !important;
  padding:70px 0 !important;
  margin:0 !important;
}
.product-img-area.thumbs-bottom {
  display: block;
}
.product-img-area.thumbs-bottom .items-carousel,
.product-img-area.thumbs-bottom .thumbs-wrapper {
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  max-width: 100%;
}
.product-img-area.thumbs-bottom.thumbs-rect .thumbs-container .flickity-prev-next-button {
  width: 2.8rem;
  height: 2.2rem;
  border: 2px solid #444;
  border-radius: 0;
  background: 0;
}
.product-img-area.thumbs-bottom.thumbs-rect .thumbs-container .flickity-prev-next-button .flickity-button-icon {
  width: 40%;
  height: 44%;
  top: 28%;
  left: 30%;
}
.product-img-area.thumbs-bottom.thumbs-circle .thumbs-container .flickity-prev-next-button {
  width: 2.8rem;
  height: 2.8rem;
  border: 2px solid transparent;
  border-radius: 100%;
  background: 0;
}
.product-img-area.thumbs-bottom.thumbs-circle .thumbs-container .flickity-prev-next-button .flickity-button-icon {
  width: 9px;
  height: 12px;
  top: 6px;
  left: 7px;
  color: #555;
}
.product-img-area.thumbs-bottom.thumbs-circle .thumbs-container .flickity-button.previous {
  left: -36px;
  opacity: .75;
}
.product-img-area.thumbs-bottom.thumbs-circle .thumbs-container .flickity-button.next {
  right: -36px;
  opacity: .75;
}
@media (min-width: 768px) {
  .product-img-area.thumbs-bottom.thumbs-circle .thumbs-container .flickity-button.previous {
    left: -24px;
  }
  .product-img-area.thumbs-bottom.thumbs-circle .thumbs-container .flickity-button.next {
    right: -24px;
  }
}
@media (min-width: 1200px) {
  .product-img-area.thumbs-bottom.thumbs-circle .thumbs-container .flickity-button.previous {
    left: -36px;
  }
  .product-img-area.thumbs-bottom.thumbs-circle .thumbs-container .flickity-button.next {
    right: -36px;
  }
}
.product-img-area.thumbs-bottom .thumbs-container {
  margin-top: 20px;
}
.product-img-area.thumbs-bottom .thumbs-container:not(.flickity-enabled) {
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  width: auto;
}
.product-img-area.thumbs-bottom .thumbs-container:not(.flickity-enabled) > * {
  display: inline-block;
}
.product-img-area.thumbs-bottom .thumbs-container:hover .flickity-prev-next-button {
  opacity: 0.75;
}
.product-img-area.thumbs-bottom .product-thumb {
  width: 7.5rem;
  margin: 0 20px 0 0;
}

.product-layout .product-single-option.four:nth-child(2) label {
    width: 55px !important;
    max-width: 55px !important;
    min-width: 55px !important;
}



@media all and (min-width: 992px) {
  .product-img-area.thumbs-bottom.thumbs-circle .product-thumb {
    width: 9.5rem;
  }
}
.product-img-area.thumbs-bottom.thumbs-circle .thumbs-container {
  width: calc(100% - 60px);
  margin: 0 auto;
}

.thumbs-wrapper {
  flex: 0 0 17.72%;
  -ms-flex: 0 0 17.72%;
  max-width: 17.72%;
}
@media (min-width: 1600px) {
  .product-layout--default .thumbs-left .thumbs-wrapper {
    position: absolute;
    left: 0;
  }
}

@media (min-width: 1600px) {
  .thumbs-left {
    position: relative;
  }
}
.thumbs-left .items-carousel {
  order: 1;
}
.thumbs-left .thumbs-wrapper {
  order: 0;
}
@media (min-width: 1600px) {
  .thumbs-left .thumbs-wrapper {
    transform: translateX(-150%);
  }
}

.thumbs-bottom .thumbs-container .previous {
  margin-left: -.7rem;
}
.thumbs-bottom .thumbs-container .next {
  bottom: .7rem;
  margin-right: -.7rem;
}
@media all and (max-width: 767px) {
  .thumbs-bottom .thumbs-container .flickity-prev-next-button {
    margin-left: -.9rem;
  }
  .thumbs-bottom .thumbs-container .previous {
    left: -2.8rem;
  }
  .thumbs-circle .thumbs-bottom .thumbs-container .previous {
    left: -.9rem;
  }
  .thumbs-bottom .thumbs-container .next {
    right: -2.8rem;
  }
  .thumbs-circle .thumbs-bottom .thumbs-container .next {
    right: -.9rem;
  }
}

.product-details-area {
  width: 100%;
  height: 100%;
}
.product-details-area .product-details__text {
  margin: 5px 0;
}
.product-details-area .vpp-atv {
  margin-bottom: 2rem;
  color: var(--c-theme);
}
.product-details-area .vpp-atv svg {
  width: 28px;
  height: 26px;
  margin-right: 8px;
}
.product-details-area .sale-price {
  margin-left: .8rem;
}

.product-nav {
  display: flex;
  display: -ms-flexbox;
  padding: 7px 12px;
  border: 2px solid #d5d5d5;
}
.product-nav svg {
  fill: #999;
}
.product-nav:hover {
  background-color: var(--c-theme);
  border-color: var(--c-theme);
}
.product-nav:hover svg {
  fill: #fff;
}

.product-popup {
  position: absolute;
  top: 100%;
  width: 15rem;
  padding: 5px;
  transition: opacity .3s;
  border-top: 2px solid var(--c-theme);
  background-color: var(--c-main-background);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  visibility: hidden;
  opacity: 0;
}
.product-popup .product-name {
  margin-top: 2px;
  font-size: 1.2rem;
  text-align: center;
}

.product-item-container {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
}
.product-item-container.next-product {
  right: 3rem;
}
.product-item-container.next-product .product-popup {
  right: 0;
}
.product-item-container.prev-product {
  left: 3rem;
}
.product-item-container.prev-product .product-popup {
  left: 0;
}
.product-item-container:hover {
  z-index: 2;
}
.product-item-container:hover .product-popup {
  visibility: visible;
  opacity: 1;
}
@media screen and (min-width: 992px) {
  .product-item-container {
    display: block;
  }
}

.social-sharing__title {
  display: block;
  margin-bottom: 18px;
}

.social-sharing__link {
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #e0e0e0;
}
.social-sharing__link svg {
  display: flex;
  display: -ms-flexbox;
  width: 30px;
  height: 30px;
  padding: 8px;
  fill: #cdcdcd;
}
.social-sharing__link:hover svg {
  fill: #fff;
}

.facebook:hover {
  background-color: #3f83c3;
  border-color: #3f83c3;
}

.twitter:hover {
  background-color: #27c0c8;
  border-color: #27c0c8;
}

.pinterest:hover {
  background-color: #de2f2f;
  border-color: #de2f2f;
}

.tabs .tabs-content__item {
  margin: 0;
}

.tabs-nav {
  width: 100%;
  min-width: 100%;
}
#shopify-section-template--16945256431831__template-product-details-tabs .tabs-nav__item.active h4 {
    color: #000 !important;
}
.product-details-tabs .tabs-nav__item {
  margin-right: -2px;
  border-right: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.product-details-tabs .tabs-nav__item h4 {
  margin: 0;
  font-family: var(--font-heading);
  font-size: 15pt;
  letter-spacing: 0;
  line-height: 2.2rem;
  text-transform: uppercase;
}
.product-details-tabs .tabs-nav__item a {
  display: block;
  padding: 1.6rem 3.5rem;
}
.product-details-tabs .tabs-nav__item.active {
  border-left: 2px solid var(--c-theme);
  border-right: 0;
}
.product-details-tabs .tabs-nav__item:last-child {
  display: none;
  height: 100%;
  margin-bottom: -1px;
}

.tabs-content__item .product-videos .product-description {
  padding: 0 1.5rem;
}
@media all and (max-width: 991px) {
  .tabs-content__item .product-videos div[class*="grid-items__"] {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.product-details-tabs .product-details-tabs__classic .tabs,
.product-details-tabs .product-details-tabs__classic .tabs-nav {
  display: flex;
  flex-direction: column;
}
.product-details-tabs .product-details-tabs__classic .tabs {
  min-height: 450px;
  border: 1px solid #efefef;
}
.product-details-tabs .product-details-tabs__classic .tabs-nav {
  margin-left: -1px;
}
.product-details-tabs .product-details-tabs__classic .tabs-content {
  width: 100%;
  padding: 48px 60px;
}
.product-details-tabs__modern p, .product-details-tabs__modern span, .product-details-tabs__modern li {
    color: #000;
}
.product-details-tabs__modern p, .product-details-tabs__modern span, .product-details-tabs__modern li, .s4com-container span, .s4com-container p {
    font-size: 16px !important;
    line-height: 1.6 !important;
}

@media all and (min-width: 768px) {
  .product-details-tabs .product-details-tabs__classic .tabs {
    -ms-flex-direction: row;
    flex-direction: row;
    min-height: 350px;
  }
  .product-details-tabs .product-details-tabs__classic .tabs-nav {
    width: 280px;
    min-width: 280px;
  }
  .product-details-tabs .product-details-tabs__classic .tabs-nav .tabs-nav__item:last-child {
    display: list-item;
    height: 100%;
    min-height: 80px;
  }
}
@media all and (max-width: 991px) {
  .product-details-tabs .product-details-tabs__classic .tabs-content {
    padding: 32px 40px;
  }
}

@media all and (min-width: 768px) {
  .product-details-tabs .product-details-tabs__modern .tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #efefef;
    margin:0;
  }
  .product-details-tabs .product-details-tabs__modern .tabs-nav__item {
    margin: 0;
    margin-bottom: -1px;
    border: 0;
  }
  .product-details-tabs .product-details-tabs__modern .tabs-nav__item a {
    transition: .35s;
    border: 1px solid transparent;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .product-details-tabs .product-details-tabs__modern .tabs-nav__item a:hover {
    border: 1px solid #efefef;
  }
  .product-details-tabs .product-details-tabs__modern .tabs-nav__item.active a {
    border: 1px solid #e0e0e0;
    border-bottom-color: transparent;
    border-bottom-width: 2px;
    background-color: var(--c-main-background);
  }
  .product-details-tabs .product-details-tabs__modern .tabs-content {
    background: #fff;
    padding: 20px 40px;
    border-top:0 !important;
    border: 1px solid #e0e0e0;
  }
}



@media all and (max-width: 767px) {

  .product-details-tabs .product-details-tabs__modern .tabs-nav {
    margin-left: -1px;
    width: 100%;
    min-width: 100%;
  }
}

.product-layout .full-width.product-badge__advanced {
  background-color: #f4f4f4;
}
.product-layout .full-width.product-badge__modern {
  background-color: #f6f6f6;
}

.product-badges .promo-icon {
  position: relative;
  width: auto;
}
.product-badges .promo-container {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -ms-flex-align: center;
}
.product-badges .promo-image img {
  display: block;
}

.product-badges.bottom-full {
  align-items: center;
  width: auto;
  margin: 2.4rem auto 0;
  padding: 0;
}
.product-badges.bottom-full .product-badge {
  flex: 1;
  -ms-flex: 1;
  padding: 0 1.5rem;
}
.product-badges.bottom-full .promo-container {
  padding-left: 3rem;
  padding-right: 3rem;
}
.product-badges.bottom-full .promo-image img {
  height: 3.4rem;
  margin-right: 1.8rem;
}
.product-badges.bottom-full .classic .promo-container {
  margin-top: 3rem;
  border: 1px solid #efefef;
  border-bottom: 2px solid var(--c-theme);
  box-shadow: initial;
}
.product-badge__advanced .product-badges.bottom-full {
  margin: 48px auto 0;
  padding: 2rem 0 3.5rem;
}
.product-badges.bottom-full .advanced .promo-container {
  margin-top: 15px;
  border: 0;
  background-color: #fff;
}
.product-badge__modern .product-badges.bottom-full {
  margin: 12.2rem auto 0;
  padding: 9.3rem 0 10.8rem;
}
@media all and (max-width: 1399px) {
  .product-badge__modern .product-badges.bottom-full {
    margin-top: 6rem;
    padding: 4rem 0 5.5rem;
  }
}
@media all and (max-width: 767px) {
  .product-badge__modern .product-badges.bottom-full {
    margin-top: 4.5rem;
    padding: 2rem 0 3.5rem;
  }
}
.product-badges.bottom-full .modern .promo-container {
  margin-top: 15px;
  border: 0;
  background-color: transparent;
}
.product-badges.bottom-full .modern .promo-icon {
  color: #333333;
}
.product-badges.bottom-full .modern .promo-title {
  font-family: var(--font-extra);
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.7rem;
}

.product-badges.side-fixed {
  margin: 30px 0 0;
}
.product-badges.side-fixed .product-badge {
  width: 100%;
}
.product-badges.side-fixed .promo-container {
  margin-bottom: 10px;
  padding: 10px 27px;
}
.product-badges.side-fixed .promo-image {
  margin-right: 1.8rem;
}
.product-badges.side-fixed .promo-title {
  color: #444;
  font-weight: 700;
}
.product-badges.side-fixed .classic .promo-container {
  border: 1px solid #e0e0e0;
  box-shadow: initial;
}
.product-badges.side-fixed .classic .promo-title {
  font-family: var(--font-heading);
  font-size: 1.2rem;
  letter-spacing: .25em;
}
.product-badges.side-fixed .advanced .promo-container {
  border: 1px solid #efefef;
  background-color: #fafafa;
}
.product-badges.side-fixed .advanced .promo-title {
  font-family: var(--font-body);
  font-size: 1.4rem;
  letter-spacing: .04em;
}

.product-description {
  color: #5c5c5c;
  font-family: var(--f-p-d);
  font-size: 1.4rem;
  line-height: 2.3rem;
}
.product-description h2,
.product-description h3,
.product-description h4 {
  margin-bottom: 2.4rem;
}
.product-description h5,
.product-description h6,
.product-description p {
  margin-bottom: 1.6rem;
}
.product-description iframe {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.product-details-summary .product-description {
  margin-top:1.5rem;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    line-height: 1.5;
    max-width: 370px;
}

.spr-container {
  padding: 0 !important;
  border: 0 !important;
}
.spr-container .spr-review-header-byline {
  font-style: normal;
  letter-spacing: .05em;
}

.spr-header-title,
.spr-form-title {
  color: #444444;
  font-size: 1.8rem !important;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.spr-summary-starrating {
  font-size: 1.3rem;
}

.spr-summary-actions-newreview {
  color: #444444;
  font-family: var(--font-heading);
  font-weight: 700;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.spr-form-title {
  margin-bottom: 35px;
}

.spr-form-contact-name,
.spr-form-contact-email,
.spr-form-review-rating,
.spr-form-review-title {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
}

.spr-form-label,
.spr-form-input {
  color: #777;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem !important;
}

.spr-form-label {
  display: inline-block;
  min-width: 88px;
  margin: 12px 0;
  padding-right: 10px;
}

input.spr-form-input {
  height: 46px;
  padding: 10px 20px;
  border: 1px solid #efefef;
}

.spr-form-review-body .spr-form-label {
  width: 100%;
}
.spr-form-review-body .spr-form-input-textarea {
  padding: 20px;
}

.card-wrapper {
  margin-bottom: 2rem;
}

.card-wrapper .product-name {
  line-height: 1.9rem;
}

.mfp-product-gallery .mfp-content,
.mfp-product-gallery .relative {
  height: 100%;
}
.mfp-product-gallery .mfp-galleryContainer {
  width: 100%;
}
.mfp-product-gallery .mfp-container {
  background-color: #ffffff;
}
.mfp-product-gallery .product-img {
  position: relative;
  margin: 0 auto;
  text-align: center;
}
.mfp-product-gallery video,
.mfp-product-gallery .image-bg {
  display: inline-block;
  position: relative;
  padding: 20px 30px;
  background-color: transparent;
}
.mfp-product-gallery .image-bg {
  width: auto;
  height: auto;
}
.mfp-product-gallery model-viewer {
  padding: 0;
}
.mfp-product-gallery .mfp-arrow {
  width: 35px;
  height: 28px;
  margin-top: -17px;
  border: 2px solid #333333;
  background-repeat: no-repeat;
  background-position: center center;
}
.mfp-product-gallery .mfp-arrow::before, .mfp-product-gallery .mfp-arrow::after {
  display: none;
}
.mfp-product-gallery .mfp-arrow-left {
  left: 40px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='7px' height='10px' fill='rgb(21,21,21)' viewBox='0 0 7 10'><path fill-rule='evenodd' d='M5.057,9.998 L-0.001,4.998 L5.057,-0.002 L6.214,1.126 L2.297,4.998 L6.214,8.870 L5.057,9.998 Z'/></svg>");
}
.mfp-product-gallery .mfp-arrow-right {
  right: 40px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='7px' height='10px' fill='rgb(21,21,21)' viewBox='0 0 7 10'><path fill-rule='evenodd' d='M1.942,9.998 L7.000,4.998 L1.942,-0.002 L0.785,1.126 L4.702,4.998 L0.785,8.870 L1.942,9.998 Z'/></svg>");
}
.mfp-product-gallery .mfp-close {
  top: 25px;
  right: 15px;
  font-weight: 400;
}
.mfp-product-gallery .mfp-expander {
  position: absolute;
  right: 32px;
  bottom: 32px;
  width: 28px;
  height: 28px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 215.35 215.35' fill='rgb(21,21,21)'><g><path d='M7.5,65.088c4.143,0,7.5-3.358,7.5-7.5V25.61l48.305,48.295c1.465,1.464,3.384,2.196,5.303,2.196 c1.92,0,3.84-0.732,5.304-2.197c2.929-2.929,2.929-7.678-0.001-10.606L25.604,15.002h31.985c4.142,0,7.5-3.358,7.5-7.5 c0-4.142-3.357-7.5-7.5-7.5H7.5c-4.143,0-7.5,3.358-7.5,7.5v50.087C0,61.73,3.357,65.088,7.5,65.088z'/><path d='M207.85,150.262c-4.143,0-7.5,3.358-7.5,7.5v31.979l-49.792-49.792c-2.93-2.929-7.678-2.929-10.607,0 c-2.929,2.929-2.929,7.678,0,10.606l49.791,49.791h-31.977c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h50.086 c4.143,0,7.5-3.358,7.5-7.5v-50.084C215.35,153.62,211.992,150.262,207.85,150.262z'/><path d='M64.792,139.949L15.005,189.74v-31.978c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v50.086c0,4.142,3.357,7.5,7.5,7.5 h50.084c4.142,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H25.611l49.788-49.793c2.929-2.929,2.929-7.678-0.001-10.607 C72.471,137.02,67.722,137.02,64.792,139.949z'/><path d='M207.85,0.002h-50.086c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h31.979l-48.298,48.301 c-2.929,2.929-2.929,7.678,0.001,10.607c1.464,1.464,3.384,2.196,5.303,2.196c1.919,0,3.839-0.733,5.304-2.197l48.298-48.301v31.98 c0,4.142,3.357,7.5,7.5,7.5c4.143,0,7.5-3.358,7.5-7.5V7.502C215.35,3.359,211.992,0.002,207.85,0.002z'/></g></svg>");
  background-repeat: no-repeat;
  cursor: pointer;
  opacity: .65;
  z-index: 1;
}
.mfp-product-gallery .mfp-expander:hover {
  opacity: 1;
}
.mfp-product-gallery .media-type-badge {
  display: none;
}

.product-details-tabs {
  margin-top: 6rem;
  color: #5c5c5c;
  font-size: 1.4rem;
  line-height: 2.3rem;
}
.product-details-tabs table {
  width: 100%;
  border-top: 1px solid #efefef;
  border-left: 1px solid #efefef;
}
.product-details-tabs tr:nth-of-type(even) {
  background-color: #fbfbfb;
}
.product-details-tabs th,
.product-details-tabs td {
  height: 40px;
  padding-left: 30px;
  border-right: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.product-layout {
  position: relative;
  color: #686b6a;
  font-size: 1.5rem;
  z-index: 1;
}
.product-layout .product-main {
  margin: 0;
}
.product-layout h1.product-name {
  color: #333333;
  font-size: var(--s-p-n);
      font-size: 20px !important;
    font-weight: 800;
    line-height: normal;
    font-family: "Inter", sans-serif;
}

.product-layout .product-price span {
  display: inline-block;
}
.product-layout .current-price {
  font-size: 2.1rem;
}
.product-layout .old-price span {
  font-size: 1.4rem;
  font-weight: 400;
  text-decoration: line-through;
}
.product-layout .in-stock {
  text-transform: lowercase;
}
.product-layout .product-review {
  cursor: pointer;
}

.product-layout .limited-brand {
  margin-top: 25px;
}
.product-layout .link-page-popup {
  color: var(--c-theme);
  font-family: Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: lowercase;
}
.product-layout .size-chart {
  width: 100%;
  margin-top: .3rem;
}
.product-layout .product-deal {
  max-width: 22rem;
}
.product-layout .product-price {
  margin: 1.6rem 0 2rem;
}
.product-layout .btn-wishlist svg,
.product-layout .btn-compare svg {
  color: #acacac;
}

#satcb_bar.designNo3 .satcb_btn button .product-price {
  display:none !important;
}

.product-layout .product-form {
  margin-top: 1rem;
  position:relative;
  padding-top:210px;
  min-height:300px;
}

body#supergreens-powder-the-ultimate-superfood-powder .product-layout .product-form,
body#longevity-stack-supergreens-the-ultimate-superfood-powder .product-layout .product-form {
padding-top:125px;

}


.product-layout .product-sku {
  margin-bottom: 5px;
}
.product-layout .add-to-cart {
  margin-bottom: 1.3rem;
}
.product-layout .payment-info {
  margin: 3rem 0;
}
.product-layout .payment-info h5 {
  display: inline-block;
  padding: 3px 15px 15px 0;
  background-color: var(--c-main-background);
  color: var(--c-product-name);
  font-family: var(--f-p-n);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1;
}
.product-layout .payment-info__image-container {
  margin-top: -24px;
  padding: 25px 15px;
  border: 1px solid #ececec;
}
.product-layout .payment-info__image-container img {
  height: auto;
  padding: 5px;
}
.product-layout .payment-info__image-container svg {
  width: auto;
  height: 42px;
  padding: 5px;
}
.product-layout__main-media .items-carousel:hover .flickity-prev-next-button {
  opacity: 0.75;
}
.product-layout__main-media .flickity-prev-next-button {
  opacity: 0;
}
.product-layout__main-media .flickity-prev-next-button.previous {
  left: 20px;
}
.product-layout__main-media .flickity-prev-next-button.next {
  right: 20px;
}

.product-layout__classic h1.product-name {
  color: #444444;
}
.product-layout__classic .current-price {
  font-size: 1.8rem;
}

.product-layout--grid > .full-width-container,
.product-layout--grid > .container-1500 {
  padding: 0 15px;
  overflow: initial;
}
@media all and (min-width: 1400px) {
  .product-layout--grid > .full-width-container,
  .product-layout--grid > .container-1500 {
    padding: 0 40px;
  }
  .product-layout--grid > .full-width-container .product-details-area,
  .product-layout--grid > .container-1500 .product-details-area {
    max-width: 520px;
    margin-right: auto;
    padding-left: 8rem;
  }
}
.product-layout--grid .product-img-area {
  display: block;
  width: 100%;
}
.product-layout--grid .product-img-area > .row {
  margin: 0 -7px 0 -8px;
}
.product-layout--grid div[class^="grid-items__"] {
  padding: 15px 7px 0 8px;
}

@media all and (min-width: 992px) {
  .product-layout--center-image h1.product-name {
    margin-top: 3.5rem;
  }
  .product-layout--center-image .product-details-area,
  .product-layout--center-image .product-details-summary {
    -ms-flex: 0 0 22.89%;
    flex: 0 0 22.89%;
    max-width: 22.89%;
  }
  .product-layout--center-image .product-img-area {
    -ms-flex: 0 0 51.22%;
    flex: 0 0 51.22%;
    max-width: 51.22%;
  }
  .product-layout--center-image .product-details-area {
    color: #444444;
  }
}
@media all and (max-width: 991px) {
  .product-layout--center-image .product-details-summary {
    display: none;
  }
}

.product-info-tips {
  margin-bottom: 2.4rem;
  font-size: 1.5rem;
}

.info-tips__container {
  padding: .5rem 0;
}
.info-tips__container svg {
  margin-right: 1rem;
}

.product-layout--sticky-form h1.product-name {
  width: 100%;
  margin-bottom: 2rem;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 3.5rem;
}
.product-layout--sticky-form .product-details__text {
  margin: .9rem 0;
}
.product-layout--sticky-form .product-details__text label {
  color: #444444;
  font-weight: 700;
}

@media all and (min-width: 992px) {
  .product-form-sticky {
    position: sticky;
    top: 80px;
  }
  .product-form-sticky .product-price {
    margin-bottom: 2.1rem;
  }

  .product-form-sticky .old-price {
    margin-right: .8rem;
    font-size: 1.7rem;
  }
  .product-form-sticky .product-single-option {
    display: block;
  }
  .product-form-sticky .option-name {
    display: block;
    margin-bottom: 1.6rem;
  }

  .product-form-sticky .product-form {
    margin-bottom: 2rem;
  }
  .product-form-sticky > *:last-child {
    margin-bottom: 0;
  }
  .product-form-sticky .btn-wishlist,
  .product-form-sticky .btn-compare {
    width: auto;
    height: auto;
    border: 0;
    background: transparent !important;
    color: #444;
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: initial;
  }
  .product-form-sticky .btn-wishlist svg,
  .product-form-sticky .btn-compare svg {
    width: 14px;
    height: 14px;
    margin-right: .9rem;
    color: var(--c-theme);
  }
  .product-form-sticky .btn-wishlist span,
  .product-form-sticky .btn-compare span {
    display: inline-block;
  }
  .product-form-sticky .btn-wishlist.is-active,
  .product-form-sticky .btn-compare.is-active {
    color: #91dbff;
    background-color: transparent !important;
  }
  .product-form-sticky .btn-wishlist.is-active svg,
  .product-form-sticky .btn-compare.is-active svg {
    color: inherit;
  }
  .product-form-sticky .btn-wishlist:hover,
  .product-form-sticky .btn-compare:hover {
    color: var(--c-theme) !important;
  }
  .product-form-sticky .btn-wishlist:hover svg,
  .product-form-sticky .btn-compare:hover svg {
    color: var(--c-theme);
  }
  .product-form-sticky .btn-compare svg {
    width: 1.7rem;
    margin-right: .6rem;
  }
  .product-form-sticky .qty-control {
    width: 8rem;
  }
}

.template-product__sidebar .slide-group .product-name {
  display: -webkit-box;
  font-family: var(--font-body);
  font-size: 1.4rem;
  line-height: 1.5rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.template-product--sidebar .product-details-tabs__modern,
.template-product--sidebar .product-details-tabs__classic {
  padding: 0;
}
@media (min-width: 992px) {
  .template-product--sidebar {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: min-content 1fr;
  }
  .template-product--sidebar .shopify-section {
    grid-column: 1 / span 4;
  }
  .template-product--sidebar .products-template,
  .template-product--sidebar .shopify-section:nth-of-type(3) {
    grid-column: 1 / span 3;
  }
  .template-product--sidebar .product-template__main {
    grid-row: 1 / span 1;
  }
  .template-product--sidebar .shopify-section:nth-of-type(3) {
    grid-row: 2 / span 1;
  }
  .template-product--sidebar .template-product__sticky-form,
  .template-product--sidebar .template-product__sidebar {
    grid-column: 4 / span 1;
    grid-row: 1 / span 2;
  }
}

#shopify-section-products-related {
  margin-top: 7rem;
  margin-bottom: 9rem;
}

@media all and (min-width: 768px) {
  .sticky-dir-down,
  .sticky-dir-up {
    position: -webkit-sticky;
    position: sticky;
    height: 100%;
  }

  .sticky-dir-up {
    top: 5.6rem;
  }

  .sticky-dir-down {
    bottom: 3rem;
    align-self: flex-end;
  }

  .product-form-sticky .option-img-variant,
  .product-form-sticky .option-img-custom {
    width: 6rem;
    height: 6rem;
  }
}
@media all and (max-width: 767px) {
  .product-img-area.thumbs-bottom {
    display: block;
  }

  .product-layout .product-main {
    display: block;
  }

  .product-layout .product-img-area {
    margin-bottom: 5rem;
  }

  .product-img-area.thumbs-bottom .product-thumb {
    margin-right: 10px;
  }

  .product-details-tabs .tabs-nav__item a {
    padding: 1.2rem 2.8rem;
  }

  .tabs-content {
    padding: 1.6rem 2.8rem;
  }
}
@media all and (max-width: 575px) {
  .mfp-product-gallery .mfp-arrow-right {
    right: 10px;
  }
  .mfp-product-gallery .mfp-arrow-left {
    left: 10px;
  }
  .mfp-product-gallery .mfp-close {
    top: 6px;
    right: -4px;
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
}



/* new design  */
.product-details-summary .nav-to-tab-desc {
  display:none;
}

.product-form {
    box-shadow: none !important;
    max-width: 390px;
}


.loox-rating .loox-icon {
    color: #f2d96f !important;
  width:18px;
  height:18px;
}

.product-actions {
    width: 100%;
}

.nmn_product {
    background: #fff;
    padding: 30px 0 0;
}
.product-actions span.add-cart__text {
    position: relative;
}
.product-actions span.add-cart__text:after {
    display: inline-block;
    content: "";
    vertical-align: middle;
    width: 10px;
    height: 1px;
    background: #fff;
    margin-left: 10px;
}

.product-form .btn-add-cart .product-price {
margin:0 10px 0 0;
  font-size:inherit;
  display:inline-block;
}
.product-form .btn-add-cart .current-price {
  color:inherit;
  font-weight:inherit;
}
.product-form .btn-add-cart {
 border-left: 2px solid #000;
  padding-left:10px;
  display:inline-block;
  width:100%;
  padding:14px 30px;
  border:none;
  font-family: "Inter", sans-serif;
      text-transform: capitalize;
  border-radius:6px;
  font-size:18px;
  height:auto;
}

.per-gram\=price {
    color: red;
}
.sls-selling-plan-description-content .wfx-product__subscription-features p, .sls-selling-plan-description-content .wfx-product__subscription-features ul li {
    position: relative;
    margin: 6px 0;
    font-family: "Inter", sans-serif;
    padding-left: 20px;
    font-weight: normal;
    letter-spacing: 0.3;
    font-size: 11px;
  display:block;
}
.sls-selling-plan-description-content .wfx-product__subscription-features ul {
  padding:0;
  margin:0;
}

.sealsubs-container .sls-active .sls-selling-plan-group-options {
    border-top: 1px solid #000;
    padding-top: 5px;
    position: relative;
}

.sls-selling-plan-group-options {
    margin-top: 13px !important;
    margin-left: -24px;
      position: relative;
}
.sealsubs-target-element label.sls-selling-plan-group-name {
    font-weight: 800;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    display: block;
    margin-bottom: 0;
}
.sls-total-price .sls-original-price {
    color: rgba(0, 0, 0, 0.5) !important;
    font-style: normal !important;
    font-weight: 800;
    font-size: 14px !important;
    font-family: "Inter", sans-serif;
}

.sls-price {
    color: #000 !important;
    font-weight: 800;
    font-size: 14px;
    font-family: "Inter", sans-serif;
}


.sls-active .sls-selling-plan-description-content {
    margin-left: -30px;
}
.sealsubs-container .sls-option-container:not(.sls-active) {
    opacity: 0.4;
}

.sealsubs-container .sls-price-below {
  display:none;
}

.sls-selling-plan-description-content .wfx-product__subscription-features ul li:before {
    content: "";
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: rotate(-38deg) skew(0deg);
    width: 12px;
    height: 6px;
    display: block;
    position: absolute;
    left: 1px;
    top: 5px;
    opacity: 0.6;
}
.sealsubs-container .sls-option-container .sls-selling-plan-group-options label {
  display:none;
}
body .sealsubs-container .sls-select-container select.sls-select {
    border: 1px solid #2b1a1a !important;
    position: absolute;
    right: 0;
    left: auto;
    top: 20px;
    width: auto;
    font-size: 11px;
    outline: none !important;
    border-radius: 40px !important;
    background-color: #fff !important;
    color: #2b1a1a !important;
    padding: 3px 27px 3px 8px !important;
}

.info-tips__container .tip_icon img {
    width: 100%;
}
.info-tips__container .tip_icon {
  margin-right: 5px;
  width: 22px;
  height: 22px;
      display: inline-flex;
    align-items: center;
    justify-content: center;
}



@media(max-width:767px) {
  .sealsubs-target-element .sls-custom-radio, .sls-option[type="radio"] + span.sls-custom-radio {
    border-color: #11d335 !important;
}
  .sealsubs-target-element .sls-option[type="radio"] + .sls-custom-radio:after {
    box-shadow: inset 1em 1em #11d335 !important;
}
.sealsubs-target-element .sls-option[type="radio"]:checked + .sls-custom-radio:after {
    width: 6px !important;
    height: 6px !important;
}

  .small_mighty .title_content {
    text-align: center;
    max-width: 100%;
    margin-bottom: 25px;
}
.product-faq .cgt-desc li a {
  font-size:15px;
  padding:7px 0 7px 30px;
}

  
.product-faq .cgt-desc {
  margin:0 !important;
  display:block !important;
}
.product-faq .cgt-content,
  .product-faq .tabs_block {
    width:100% !important;
    padding:0 !important;
  }

.product-details-tabs .tabs-nav__item {
    background: #fff !important;
    position: relative;
    border: 1px solid #ddd;
    margin: 0;
    font-size: 1.4rem;
    letter-spacing: 0.2em;
    line-height: 2.2rem;
    text-transform: uppercase;
  padding: 0;
}
.product-details-tabs .tabs-nav__item.active {
    background: #003784 !important; border: none;
    color: #fff !important;
}
.t-shirt-big #shopify-section-template--16945256431831__template-product-details-tabs .tabs-nav__item.active h4 {
    color: #fff!important;
}
.product-details-tabs .tabs-nav__item h4 {
    font-size: 1.4rem !important;
    letter-spacing: .2em !important;
    line-height: 2.2rem !important;
}
.product-details-tabs .tabs-nav__item a {
    padding: 1.2rem;
}
.product-details-tabs .product-details-tabs__modern .tabs-content {
    background: #fff;
    padding: 2.2rem 1.2rem  1.2rem  !important;
}

#shopify-section-template--16945256431831__template-product-details-tabs .tabs-nav__item.active h4 {
    color: #fff!important;
}

  
  
}