/*===================== Typography =====================*/
/*===================== Color =====================*/
/* Product card configuration */
/* Wishlist & Compare configuration */
/* Footer configuration */
/* User Notice Messages */
.template-collection {
  overflow: unset;
}

.page-title {
  margin-bottom: 4rem;
  font-family: var(--font-body);
  font-size: 2.5rem;
  font-weight: 400;
}

.collection-sort__by,
.collection-filter__by,
.collection-group__by {
  padding: 1rem 1.4rem;
  transition: all .39s;
  background-color: #ffffff;
  color: #444;
  line-height: 1.2em;
}
.collection-sort__by .icon-dir-down,
.collection-filter__by .icon-dir-down,
.collection-group__by .icon-dir-down {
  width: 8px;
  height: 5px;
  margin-left: auto;
}

.collection-sort__by--classic,
.collection-group__by--classic {
  padding-right: 3.4rem;
}

.collection-sort__by--advanced {
  padding-left: 0;
}

.collection-filter__by .icon-filter {
  width: 20px;
  height: 15px;
}

.group-wrapper b {
  margin-left: 6px;
  font-weight: 400;
}

.collection-grid__switcher {
  margin: 0 2px 0 3px;
  padding: 11px;
  cursor: pointer;
}
.collection-grid__switcher svg {
  display: block;
  width: 1.4rem;
  height: 1.4rem;
  color: #d5d5d5;
}
.cfd-classic .collection-grid__switcher {
  margin: 0 5px;
  border: 1px solid transparent;
}
.cfd-classic .collection-grid__switcher svg {
  color: #dfdfdf;
}

.icon-grid-three::before {
  content: '';
  display: block;
  width: 1.4rem;
  height: .9rem;
  margin-bottom: 1px;
  border-top: .4rem solid #dfdfdf;
  border-bottom: .4rem solid #dfdfdf;
}
.icon-grid-three::after {
  content: '';
  display: block;
  width: 14px;
  border-bottom: 4px solid #dfdfdf;
}

.collection-sort {
  margin-bottom: 4rem;
}

.pagination-container {
  margin-left: auto;
}

.collection-sort__header {
  padding: 1.2rem 0;
  border-bottom: 1px solid #ececec;
}
.collection-sort__header .medium-9 > *,
.collection-sort__header .paginate-item {
  margin: .5rem .2rem .5rem 0;
}
.collection-sort__header .col-padding {
  position: initial;
}

.filter-wrapper .dd-pp__list {
  padding: 1rem .8rem 1.2rem;
}
.filter-wrapper .dd-pp__list .sidebar-block {
  padding: 1.2rem .8rem;
  max-width: 100%;
}
@media all and (min-width: 768px) {
  .filter-wrapper .dd-pp__list {
    padding: 1.8rem 1.2rem 2.1rem;
  }
  .filter-wrapper .dd-pp__list .sidebar-block {
    padding: 1.5rem 1.8rem 2.7rem;
  }
}
@media all and (min-width: 1200px) {
  .filter-wrapper .dd-pp__list {
    padding: 3rem 1.5rem 4rem;
  }
  .filter-wrapper .dd-pp__list .sidebar-block {
    padding: 2.5rem 2.5rem 3.5rem;
  }
}

.active-tags,
.filter-result_container {
  padding: 1.2rem 0;
}
@media all and (min-width: 1200px) {
  .active-tags,
  .filter-result_container {
    padding: 2rem 0;
  }
}

@media all and (min-width: 1200px) {
  .filter-result_container {
    margin-bottom: -3rem;
  }
}

.active-tags {
  border-bottom: 1px solid #ececec;
}

.active-tag__item {
  margin: .4rem 1.8rem .4rem 0;
  padding: 1.2rem 1.8rem;
  border-radius: 1.8rem;
  background-color: #fff;
  box-shadow: 0 1px 4px 2px #e6e6e6;
  line-height: 1;
}
.active-tag__item svg {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  margin-right: .9rem;
  padding: 3px;
  color: #333;
}
.active-tag__item.clear-tags {
  margin-left: auto;
}
@media all and (min-width: 1200px) {
  .active-tag__item {
    min-width: 13.6rem;
    margin: .6rem 2rem .6rem 0;
    padding: 1.3rem 2rem 1.2rem;
    border-radius: 2rem;
  }
  .active-tag__item svg {
    margin-right: 1.3rem;
  }
}

.mobile-sidebar.left {
  order: -1;
}
.mobile-sidebar.right {
  order: 13;
}
.mobile-sidebar .nav-list__link {
  text-transform: capitalize;
}

.sidebar-block {
  margin-top: 5rem;
}
.sidebar-block:first-child {
  margin-top: 0;
}
.sidebar-block .block-title {
  margin-bottom: 18px;
}
.sidebar-block a {
  color: #3a3a3a;
}
.sidebar-block .sidebar-carousel .flickity-prev-next-button {
  top: -29px;
}

.filter-color {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.filter-color .nav-level__0 {
  margin-bottom: 1.1rem;
}
.filter-color .nav-level__0 .nav-list__link {
  position: relative;
  margin-right: 1rem;
  padding: 1rem;
  border: 1px solid transparent;
}
.filter-color .nav-level__0 .nav-list__link.tick-circle {
  border-radius: 50%;
}
.filter-color .nav-level__0 .nav-list__link.option-label-white {
  border: 1px solid #333;
}
.filter-color .nav-level__0 .nav-list__link.option-label-white .active svg {
  fill: #333;
}
.filter-color .ticksign {
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  line-height: 9px;
}
.filter-color .ticksign-svg {
  border: 0;
}

.filter-list .tick-circle svg {
  border-radius: 50%;
}

.filter-count {
  margin-left: 5px;
  color: #bdbdbd;
}

.sub-collection {
  margin: 6rem 0 18rem;
}

.search-result {
  position: relative;
  z-index: 1;
}
.search-result h5 {
  font-size: 1.5rem;
}
.search-result p {
  font-size: 1.4rem;
  margin: 10px 0 20px;
}

.collection-layout {
  margin-bottom: 9rem;
}
@media all and (min-width: 992px) {
  .collection-layout .sticky-dir-up {
    top: 7.5rem;
  }
}

#collectionsContent {
  opacity: 1;
}
#collectionsContent.loading > .row {
  transition: all .65s;
  opacity: .25;
}
#collectionsContent.list .collection-card__wrapper {
  margin-bottom: 32px;
}
#collectionsContent.list .grid-items__1 {
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}
#collectionsContent.list .grid-items__2 {
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}
#collectionsContent.list .grid-items__3 {
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}
#collectionsContent.list .grid-items__4 {
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}
#collectionsContent.list .grid-items__5 {
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}
#collectionsContent.list .grid-items__6 {
  flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}
#collectionsContent.list .vertical-default .product-card,
#collectionsContent.list .vertical-advanced .product-card,
#collectionsContent.list .vertical-progressive .product-card,
#collectionsContent.list .vertical-classic .product-card,
#collectionsContent.list .vertical-modern .product-card,
#collectionsContent.list .vertical-details .product-card {
  display: -ms-flexbox;
  display: flex;
}
#collectionsContent.list .vertical-default .product-description,
#collectionsContent.list .vertical-advanced .product-description,
#collectionsContent.list .vertical-progressive .product-description,
#collectionsContent.list .vertical-classic .product-description,
#collectionsContent.list .vertical-modern .product-description,
#collectionsContent.list .vertical-details .product-description {
  display: block;
  height: 2.6em;
  font-size: 1.4rem;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
}
#collectionsContent.list .vertical-default .product-img,
#collectionsContent.list .vertical-advanced .product-img,
#collectionsContent.list .vertical-progressive .product-img,
#collectionsContent.list .vertical-classic .product-img,
#collectionsContent.list .vertical-modern .product-img,
#collectionsContent.list .vertical-details .product-img {
  width: 17rem;
  min-width: 17rem;
  margin-right: 2rem;
}
#collectionsContent.list .vertical-default .product-name,
#collectionsContent.list .vertical-advanced .product-name,
#collectionsContent.list .vertical-progressive .product-name,
#collectionsContent.list .vertical-classic .product-name,
#collectionsContent.list .vertical-modern .product-name,
#collectionsContent.list .vertical-details .product-name {
  margin-bottom: 1.8rem;
}
#collectionsContent.list .vertical-default .product-label,
#collectionsContent.list .vertical-advanced .product-label,
#collectionsContent.list .vertical-progressive .product-label,
#collectionsContent.list .vertical-classic .product-label,
#collectionsContent.list .vertical-modern .product-label,
#collectionsContent.list .vertical-details .product-label {
  top: 1.2rem;
  right: auto;
  left: 1.2rem;
}
#collectionsContent.list .vertical-default .current-price,
#collectionsContent.list .vertical-advanced .current-price,
#collectionsContent.list .vertical-progressive .current-price,
#collectionsContent.list .vertical-classic .current-price,
#collectionsContent.list .vertical-modern .current-price,
#collectionsContent.list .vertical-details .current-price {
  font-size: 1.9rem;
}
#collectionsContent.list .vertical-default .product-info,
#collectionsContent.list .vertical-advanced .product-info,
#collectionsContent.list .vertical-classic .product-info,
#collectionsContent.list .vertical-modern .product-info,
#collectionsContent.list .vertical-details .product-info {
  -ms-flex-direction: column;
  flex-direction: column;
}
#collectionsContent.list .vertical-default .product-price,
#collectionsContent.list .vertical-advanced .product-price,
#collectionsContent.list .vertical-classic .product-price,
#collectionsContent.list .vertical-modern .product-price,
#collectionsContent.list .vertical-details .product-price {
  margin-bottom: 1rem;
}
#collectionsContent.list .vertical-default .spr-badge,
#collectionsContent.list .vertical-advanced .spr-badge,
#collectionsContent.list .vertical-classic .spr-badge,
#collectionsContent.list .vertical-modern .spr-badge,
#collectionsContent.list .vertical-details .spr-badge {
  margin-left: 0;
}
#collectionsContent.list .vertical-progressive .product-detail {
  text-align: left;
}
#collectionsContent.list .vertical-progressive .product-info,
#collectionsContent.list .vertical-progressive .swatch-group {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.load-more,
#infinite-more-link {
  width: 100%;
  max-width: 30rem;
  margin: 3rem auto 0;
}
.load-more svg,
#infinite-more-link svg {
  display: none;
  width: 42px;
  margin: 0 auto;
}
.load-more.disabled svg,
#infinite-more-link.disabled svg {
  display: block;
}

.load-more.disabled {
  border-color: #eee !important;
  color: #eee !important;
  pointer-events: none;
  transition: .7s ease-in;
}
.load-more.disabled span {
  display: none;
}
.load-more.disabled svg {
  width: auto;
  height: 12px;
}

/*================== jRange ==================*/
.price-range-container {
  width: 100%;
  max-width: 100%;
  margin: 4.6rem auto 0;
  padding-bottom: 3.2rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.price-range-container .back-bar {
  position: relative;
  height: .5rem;
  background-color: #eaeaea;
}
.price-range-container .back-bar .selected-bar {
  position: absolute;
  height: 100%;
  background-color: var(--c-theme);
}
.price-range-container .back-bar .pointer {
  position: absolute;
  top: 50%;
  width: 1.8rem;
  height: 1.8rem;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  border: 0.6rem solid var(--c-theme);
  border-radius: 1.8rem;
  background-color: #fff;
  cursor: col-resize;
  opacity: 1;
  z-index: 2;
}
.price-range-container .back-bar .pointer.last-active {
  z-index: 3;
}
.price-range-container .back-bar .pointer-label {
  position: absolute;
  top: 2rem;
  background-color: #ffffff;
  color: var(--c-theme);
  font-family: Arial;
  font-size: 1.8rem;
  font-weight: 700;
  white-space: nowrap;
  line-height: 1;
}
.price-range-container .back-bar .focused {
  z-index: 10;
}
.price-range-container .clickable-dummy {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.price-range-container .scale {
  top: 2px;
  position: relative;
}
.price-range-container .scale span {
  position: absolute;
  height: 5px;
  border-left: 1px solid #e5e5e5;
  font-size: 0;
}
.price-range-container .scale ins {
  position: absolute;
  top: 10px;
  left: 0;
  color: #999;
  font-size: 9px;
  line-height: 1;
  text-decoration: none;
}
.price-range-container.slider-readonly .clickable-dummy, .price-range-container.slider-readonly .pointer {
  cursor: auto;
}

.template-search .search-result .sidebar-search__input {
  width: 28rem;
  height: 4.6rem;
  padding: 0 3rem;
  border: 1px solid #666666;
  font-size: 1.7rem;
  letter-spacing: .05em;
  outline: none;
}
.template-search .search-result .search-button {
  display: inline-block;
  width: 4.6rem;
  min-width: 4.6rem;
  height: 4.6rem;
  margin-left: 4px;
  border: 1px solid #666666;
}
.template-search .search-result .search-button:hover {
  border-color: var(--c-theme);
  background-color: var(--c-theme);
  color: #ffffff;
}
.template-search .search-result .search-form {
  max-width: 33rem;
}

@media all and (min-width: 768px) {
  .collection-layout {
    margin-bottom: 12rem;
  }
}
@media (min-width: 992px) {
  .sidebar-block {
    margin-top: 7rem;
  }
  .sidebar-block:first-child {
    margin-top: 0;
  }
  .sidebar-block .block-title {
    margin-bottom: 2.5rem;
  }
  .sidebar-block .sidebar-carousel .flickity-prev-next-button {
    top: -3.6rem;
  }

  #collectionsContent.list .vertical-default .product-img,
  #collectionsContent.list .vertical-advanced .product-img,
  #collectionsContent.list .vertical-progressive .product-img,
  #collectionsContent.list .vertical-classic .product-img,
  #collectionsContent.list .vertical-modern .product-img,
  #collectionsContent.list .vertical-details .product-img {
    width: 24rem;
    min-width: 24rem;
    margin-right: 3rem;
  }
  #collectionsContent.list .vertical-default .product-label,
  #collectionsContent.list .vertical-advanced .product-label,
  #collectionsContent.list .vertical-progressive .product-label,
  #collectionsContent.list .vertical-classic .product-label,
  #collectionsContent.list .vertical-modern .product-label,
  #collectionsContent.list .vertical-details .product-label {
    top: 2.5rem;
    left: 2.5rem;
  }

  .collection-sort {
    margin-bottom: 3rem;
  }

  .collection-sort__header {
    padding: 2rem 0;
  }

  .collection-sort__by {
    width: 21.5rem;
  }
}
@media all and (min-width: 1200px) {
  .collection-layout {
    margin-bottom: 12rem;
  }

  .collection-sort__by,
  .collection-filter__by,
  .collection-group__by {
    min-width: 14rem;
    padding: 1rem 2.1rem;
  }

  .collection-sort .collection-sort__header .sort-wrapper {
    margin-left: -2.1rem;
  }
}
@media all and (max-width: 991px) {
  .collection-grid,
  .group-wrapper {
    display: none;
  }
}
@media all and (max-width: 567px) {
  .collection-sort .pagination-container {
    margin-right: auto;
    margin-left: auto;
  }
}
@media all and (max-width: 480px) {
  #collectionsContent.list .vertical-default .product-detail,
  #collectionsContent.list .vertical-advanced .product-detail,
  #collectionsContent.list .vertical-classic .product-detail,
  #collectionsContent.list .vertical-modern .product-detail,
  #collectionsContent.list .vertical-progressive .product-detail,
  #collectionsContent.list .vertical-details .product-detail {
    padding-top: 1rem;
  }
  #collectionsContent.list .vertical-default .product-img,
  #collectionsContent.list .vertical-advanced .product-img,
  #collectionsContent.list .vertical-classic .product-img,
  #collectionsContent.list .vertical-modern .product-img,
  #collectionsContent.list .vertical-progressive .product-img,
  #collectionsContent.list .vertical-details .product-img {
    width: 12rem;
    min-width: 12rem;
    margin-right: 1.2rem;
  }
  #collectionsContent.list .vertical-default .product-name,
  #collectionsContent.list .vertical-advanced .product-name,
  #collectionsContent.list .vertical-classic .product-name,
  #collectionsContent.list .vertical-modern .product-name,
  #collectionsContent.list .vertical-progressive .product-name,
  #collectionsContent.list .vertical-details .product-name {
    margin-top: 1rem;
    margin-bottom: 1.2rem;
  }
  #collectionsContent.list .vertical-default .product-deal,
  #collectionsContent.list .vertical-advanced .product-deal,
  #collectionsContent.list .vertical-classic .product-deal,
  #collectionsContent.list .vertical-modern .product-deal,
  #collectionsContent.list .vertical-progressive .product-deal,
  #collectionsContent.list .vertical-details .product-deal {
    margin-bottom: 1.8rem;
  }
  #collectionsContent.list .vertical-default .product-description,
  #collectionsContent.list .vertical-advanced .product-description,
  #collectionsContent.list .vertical-classic .product-description,
  #collectionsContent.list .vertical-modern .product-description,
  #collectionsContent.list .vertical-progressive .product-description,
  #collectionsContent.list .vertical-details .product-description {
    height: 2.6em;
    margin-bottom: 1.4rem;
  }

  select.collection-sort__by {
    width: auto;
    padding-right: 5rem;
  }

  .template-search .search-result .search-input-container {
    width: 100%;
  }
  .template-search .search-result .sidebar-search__input {
    width: 100%;
    padding: 0 2.4rem;
  }
}
