/*===================== Typography =====================*/
/*===================== Color =====================*/
/* Product card configuration */
/* Wishlist & Compare configuration */
/* Footer configuration */
/* User Notice Messages */
/*=============================================================*/
/*===================== Page Configuration ====================*/
/*=============================================================*/
.page-title {
  margin: 48px 0 15px;
  color:#000;
  font-family: var(--font-heading);
  font-size: 3.5rem; 
  font-weight: 700;
  letter-spacing: .02em;
  line-height: 27px;
}

.page-description {
  margin-bottom: 70px;
  color: var(--c-page-desc);
  font-size: 1.6rem;
}

.template-page.block-title__classic .block-title {
  border: 0;
  font-size: 2.5rem;
}
.template-page ul:not(.list-style-none) {
  list-style-position: inside;
}

/*=============================================================*/
/*====================== Cart Page Style ======================*/
/*=============================================================*/
.sale-info {
  color: #818181;
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
}

.cart-template .sidebar .btn {
    width: 100%;
    margin-bottom: 2rem;
    text-transform: uppercase;
    margin-top: 20px;
    border-color: #fc8329;
    color: #fff;
    border-radius: 4px;font-weight: 600;
    letter-spacing: 1px;
}
.cart-template .sidebar label {
  display: inline-block;
  margin: 15px 0 10px;
}
.cart-template .expandable {
  padding: 0px 20px 10px;
  border: 1px solid #efefef;
  border-top: 0;
  font-family: initial;
  
  font-size: 1.4rem;
}
.cart-template .btn-default {
  height: 40px;
  padding: 0 24px;
}

.cart-header {
  padding: 19px 30px;
  border: 1px solid #efefef;
  border-bottom: 0;
  font-family: var(--font-heading);
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: .2em;
  text-transform: uppercase;
}

.cart-list {
  padding: 0 30px;
  border: 1px solid #efefef;
  border-spacing: 0;
}
.cart-list .cart-item {
  margin: 0;
  padding: 19px 0;
  border-bottom: 1px solid #efefef;
}

.cart-list__wrapper .cart-list__img {
  width: 150px;
  min-width: 150px;
  margin-left: 0;
  margin-right: 20px;
}
.cart-list__wrapper .cart-list__name {
  flex-grow: 1;
  -ms-flex-positive: 1;
}
.cart-list__wrapper .product-name {
  font-size: 15px;
}
.cart-list__wrapper .cart-product__options {
  margin-top: 10px;
  color: #a6a6a6;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
}
.cart-list__wrapper .product-price {
  width: 100px;
  min-width: 100px;
}
.cart-list__wrapper .product-qty {
  width: 100px;
}
.cart-list__wrapper .qty-control {
  min-width: 100px;
  padding: 0;
}
.cart-list__wrapper .cart-actions {
  width: 51px;
  min-width: 51px;
  margin-right: 0;
}
.cart-list__wrapper .edit,
.cart-list__wrapper .remove {
  padding: 5px;
}
.cart-list__wrapper svg {
  display: block;
}
.cart-list__wrapper .edit svg {
  width: 12px;
  height: 12px;
  fill: #c6c6c6;
}
.cart-list__wrapper .remove {
  margin-left: 10px;
  color: #b1b1b1;
}
.cart-list__wrapper .remove svg {
  width: 9px;
  height: 9px;
}

.cart-item__info {
  flex-grow: 1;
  -ms-flex-positive: 1;
}

.cart-header > *,
.cart-item__info > div {
  margin: 0 20px;
}

.buttons-container {
  padding-bottom: 20px;
}
.buttons-container .btn {
  margin-top: 20px;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.cart-note label {
  font-family: var(--font-heading);
}

.shipping,
.cart-summary {
  margin-bottom: 30px;
}

.expander {
  cursor: pointer;
}

.cart-summary__title,
.shipping-title {
  padding: 16px 20px;
  border: 1px solid #efefef;
  font-size: 1.2rem;
  letter-spacing: .2em;
  text-align: center;
  text-transform: uppercase;
}
.cart-summary__title svg, .shipping-title svg {
  width: 8px;
  height: 8px;
  margin-left: auto;
}
.shipping.opened .cart-summary__title svg, .cart-summary.opened .cart-summary__title svg,
.shipping.opened .shipping-title svg, .cart-summary.opened .shipping-title svg {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.shipping-description {
  color: #666;
  font-family: var(--font-body);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

#shipping-calculator input[type='text'],
#shipping-calculator select {
  width: 100%;
  height: 38px;
  padding: 0 15px;
  border: 1px solid #e4e2e2;
  color: #666;
  font-family: Arial, sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.1em;
}
#shipping-calculator .get-rates {
  margin-top: 25px;
}
#shipping-calculator .error {
  margin-bottom: 10px;
  color: red;
}
#shipping-calculator .success {
  margin-bottom: 10px;
}

.summary-total {
  padding: 15px 0 25px;
  border-top: 1px solid #f0f0f0;
}

.summary-item {
  margin: 20px 0;
}
.article-details_button .btn {
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    color: #66625E !important;
    position: absolute;
    bottom: 20px;
    left: 20px;
}
.article-details_button .btn:hover {
    color: #66625E !important;
    opacity: 0.7;
}
.article-details_button .btn:focus {
    outline: 0;
    box-shadow: none;
}
.load-more_wrap {
    text-align: center;
}
.load-more_wrap button.btn.js-load-more {
    background: transparent;
    border: none;
    font-weight: bold;
    font-size: 17px;
    text-transform: initial;
    line-height: 17px;
    letter-spacing: 1px;
    color: #98A2AC;
}
.load-more_wrap button.btn.js-load-more:focus{
      box-shadow: none;
}
.cart-empty {
  max-width: 350px;
  margin: 30px auto 130px;
  padding: 20px 50px;
  border: 2px solid #efefef;
  font-size: 18px;
  line-height: 36px;
}
.cart-empty .btn-default {
  margin-top: 20px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .cart-header > *,
  .cart-item__info > div {
    margin: 0 12px;
  }

  .cart-header,
  .cart-list {
    padding-right: 12px;
    padding-left: 12px;
  }

  .cart-list__wrapper .cart-list__img {
    width: 120px;
    min-width: 120px;
  }
}
@media (max-width: 767px) {
  .page-title {
    font-size: 2.5rem;
  }

  .cart-header > *,
  .cart-item__info > div {
    margin: 0 12px;
  }

  .cart-header,
  .cart-list {
    padding-right: 12px;
    padding-left: 12px;
  }

  .cart-list__wrapper .cart-list__img {
    width: 120px;
    min-width: 120px;
    margin-right: 12px;
  }
  .cart-list__wrapper .qty-control {
    width: 75px;
    min-width: 75px;
  }
  .cart-list__wrapper .quantity {
    padding: 7px 18px;
  }
  .cart-list__wrapper .reduce,
  .cart-list__wrapper .increase {
    width: 20px;
    padding: 9px 6px;
  }

  .cart-item__info {
    width: calc(100% - 160px);
  }

  .template-customer,
  .template-contact,
  #MainContent .shopify-challenge__container {
    margin-bottom: 70px;
  }
}
@media (max-width: 575px) {
  .cart-header {
    display: none;
  }

  .cart-item__info > div {
    margin: 6px 12px;
  }

  .cart-item__info {
    display: block;
  }
  .cart-item__info .product-price {
    text-align: left;
  }

  .cart-item .qty-control,
  .cart-item .product-price {
    display: inline-block;
  }
}
.template-customer,
.template-contact,
#MainContent .shopify-challenge__container {
  margin-top: 50px;
}

.template-customer .contact-form,
.template-contact .contact-form {
  padding-top: 70px;
  padding-left: 20px;
}

.template-contact .page-description {
  margin-bottom: 60px;
}
.block-title__classic .template-contact .block-title {
  text-align: left;
}
.template-contact .form-clean .block-title {
  color: #333131;
  font-family: var(--font-heading);
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: .1em;
  text-transform: uppercase;
}
.template-contact .form-classic input,
.template-contact .form-classic textarea,
.template-contact .form-classic select {
  color: #666666;
}

.contact-column .form-clean .contact-form {
  padding: 40px 25px 20px;
  background-color: #f7f7f7;
}
.contact-column .form-clean input,
.contact-column .form-clean textarea,
.contact-column .form-clean select {
  border: 0;
}
.contact-column .form-clean input,
.contact-column .form-clean select {
  height: 4.6rem;
}

.contact-row .contact-form {
  padding-left: 0;
}
.contact-row .map-contact__dtl {
  margin-top: 70px;
}
.contact-row .form-clean .block-title {
  font-size: 2.2rem;
}
.contact-row .form-clean textarea {
  min-height: 230px;
}
@media (max-width: 991px) {
  .contact-row .form-classic {
    display: block;
  }


  
}
.contact-row .form-classic .contact-form {
  padding-right: 0;
}
.contact-row .form-classic .contact-input__container {
  padding: 50px;
  border: 1px solid #efefef;
}
.contact-row .form-classic .map-contact__dtl {
  display: block;
  width: 100%;
  margin-top: 40px;
  border: 0;
}
@media (min-width: 992px) {
  .contact-row .form-classic .map-contact__dtl {
    width: 35%;
    margin-top: 140px;
  }
}
.contact-row .form-classic textarea {
  min-height: 190px;
}

.customer-link .btn-default:last-child {
  margin-left: 12px;
}

.template-account .address,
.template-addresses .address {
  margin-bottom: 3.5rem;
}
.template-account .address p,
.template-addresses .address p {
  margin-bottom: 1.8rem;
  padding: 18px;
  border: 1px solid #c3c3c3;
  border-radius: 3px;
  background-color: #f9f9f9;
  line-height: 1.5em;
  letter-spacing: 0.02em;
}
.template-account .btn-secondary,
.template-addresses .btn-secondary {
  min-width: 80px;
  margin: 16px 28px 32px 0;
  padding: 2px 10px;
  border-width: 1px;
}
.template-account input[type="checkbox"],
.template-addresses input[type="checkbox"] {
  width: 24px;
  margin-left: 100px;
}

.template-account .responsive-table {
  width: 100%;
  margin-bottom: 50px;
  padding: 18px;
  border: 1px solid #c3c3c3;
  border-radius: 3px;
  background-color: #f9f9f9;
}
.template-account .responsive-table td,
.template-account .responsive-table th {
  padding: 3px 5px;
  text-align: left;
}
@media all and (max-width: 991px) {
  .template-account .address {
    margin-top: 3.5rem;
  }
}

.template-login .msg-notice__error {
  margin-bottom: 20px;
}
.template-login .form-message__error {
  margin-bottom: 12px;
}
.template-login .form-message__error li {
  margin-bottom: 6px;
  font-size: 16px;
}
.template-login hr {
  margin-top: 25px;
  margin-bottom: 25px;
}

#customer_login_guest {
  margin-left: 100px;
}

.template-blog .blog-section {
  margin-bottom: 170px;
}
.template-blog .page-title {
  margin: 48px 0;
}

.article {
    font-size: 1rem;
    border-radius: 6px;
    overflow: hidden;
    background: #FDF9F6;
    height: 100%; padding: 0;
}
.large-9 .article-title {
    text-align: center;
}
.single_author {
    padding: 20px 0 30px;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    -webkit-align-items: center;
}
.single_author > a {
    flex: 0 0 44px;
    margin-right: 10px;
}
.single_author p {
    font-size: 13px;
    line-height: 1.85;
    color: #121924;
    margin: 0;
}
.single_author img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}
.author-style {
    color: #043c8f;
    text-decoration: underline;
}
.home-sidebar .input-strict, .home-sidebar .msg-notice {
    margin: 2.5rem 0 2.2rem 0;
}

.article-image {
  width: 100%;
  max-width: 100%;
}

.article-details {
    width: 100%;
    max-width: 100%;
    min-height: 163px;
    padding: 20px 20px 45px;
}

.article-date {
  margin-top: 41px;
  margin-bottom: 10px;
  color: #444645;
}
.blog-style3 .article-date {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 16px;
}

.article-date {
    margin-top: 41px;
    margin-bottom: 10px;
    color: #444645;
}
.article-date__day {
  padding-right: 7px;
  font-family: var(--font-heading);
  font-size: inherit;
  font-weight: 700;
  color: #444;
}


.article-date__comment,
.article-comment__separator {
  color: #b0b0b0;
}

.article-comment__separator {
  display: inline-block;
  margin-left: 3px;
}

.article-details__title {
     color: #3b3b3b;
    font-family: var(--font-body);
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
    font-weight: 400;
    line-height: 1.2;
}

.article-details__content {
  height: 4.875em;
  color: #666;
  font-size: 1.6em;
  line-height: 1.625;
  overflow: hidden;
}

.article-details_button {
  margin-top: 2em;
  text-transform: uppercase;
}

.block-pagination {
  margin-top: 10px;
}

.blog-style1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 7em;
}
.blog-style1 .article-image__container {
  padding-top: 88.89%;
}
.blog-style1 .article-date__day {
  font-size: 4.5em;
}

.blog-style2 .article-image__container,
.blog-style3 .article-image__container {
  padding-top: 44%;
}
.blog-style3 .blog-thumb .article-image__container {
    padding-top: 50%;
}

.blog-style2 {
  margin-bottom: 8em;
}
.blog-style2 .article-date {
  margin-top: 3.2em;
  margin-bottom: .9em;
  line-height: 1;
}
.blog-style2 .article-date__comment {
  font-size: 1.2em;
}
.blog-style2 .article-details__title {
  margin-bottom: 1em;
}
.blog-style2 .article-details__content {
  color: #4a4a4a;
  line-height: 1.6875;
}

.blog-style3 {
    flex-basis: initial;
    flex: calc(33.33% - 30px);
    max-width: calc(33.33% - 30px);
    width: calc(33.33% - 30px);
    margin: 0 15px 30px;
}
.blog-style3 .article-date {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 16px;
}

.blog-style3 .article-date__comment {
  display: block;
}
.blog-style3 .article-date__day,
.blog-style3 .article-date__comment,
.blog-style3 .article-comment__separator {
  color: #444444;
}
.blog-style3 .article-details__title {
  margin-top: 15px;
}

.template-article .blog-section {
  font-size: 1rem;
}
.template-article .blog-section .sidebar-block {
  font-size: 1.6rem;
}

.article-title {
  margin-bottom: .85714em;
  color: #3b3b3b;
  font-size: 2.8em;
  font-weight: 700;
}

.template-article .article-date {
  margin-top: 4.8em;
  margin-bottom: 2px;
}
.highlight {
  margin: 40px 0 40px 40px;
  padding: 20px 30px;
  border-left: 3px solid var(--c-theme);
  background-color: #f8f8f8;
  color: #595959;
  font-size: 1.5rem;
  font-style: italic;
  letter-spacing: .05em;
  line-height: 24px;
}
.article-author {
    margin-top: 35px;
    font-size: 18px;
    color: #000;
}
.article-author a {
    display: inline-block;
    margin-right: 40px;
}

.article-content a {
    color: #043c8f;
    text-decoration: underline;
    word-break: break-all;
}
.article-author img {
    width: 60px !important;
    height: 60px !important;
    border-radius: 120px;
    object-fit: cover;
}

.article-author h5 a {
    font-size: 32px;
    line-height: 44px;
    color: #121924;
    font-weight: 700;
    margin-bottom: 0;
    display: block;
    margin: 15px 0;
}
.article-content {
    font-size: 15pt;
    line-height: 1.6;
}
.author-bio {
  color: #9a9a9a;
  font-family: Arial, Helvetica, sans-serif;
}
.author-bio h5 span {
  color: var(--c-theme);
}
.author-bio p {
  margin-top: 10px;
}

.tags-container {
  margin-top: 2px;
}

.author-bio h5,
.article-tags {
  color: #9a9a9a;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.02em;
}

.article-tags a {
  text-transform: capitalize;
}

.article-share {
  margin-top: 9px;
  margin-left: auto;
}
.article-share .addthis_toolbox span {
  border-radius: 3px;
}

.article-comment {
  margin-top: 110px;
}

.comment-title {
  margin-bottom: 30px;
  color: #444;
  font-size: 1.8rem;
  letter-spacing: .01em;
  text-transform: uppercase;
}

.comment svg {
  min-width: 70px;
  margin-right: 36px;
}
.comment .commenter-name {
  color: var(--c-theme);
  font-size: 1.4rem;
  font-style: italic;
}
.comment .comment-content {
  display: block;
  margin-bottom: 12px;
  color: #555;
  font-size: 1.6rem;
}
.comment time {
  color: #9a9a9a;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  font-style: italic;
}

.comment-block {
  margin-bottom: 60px;
}

.comment-form-container {
  margin-top: 110px;
  padding: 55px 60px;
  background-color: #f7f7f7;
}
.comment-form-container label {
  width: 135px;
  min-width: 135px;
  margin-right: 15px;
  color: #444;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.02em;
}
.comment-form-container .label-textarea {
  margin-top: 13px;
}
.comment-form-container input[type="text"],
.comment-form-container input[type="email"],
.comment-form-container textarea {
  flex-grow: 1;
  -ms-flex-positive: 1;
  margin-bottom: 10px;
  padding: 15px 23px;
  border: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
}
.comment-form-container input[type="submit"] {
  margin-top: 20px;
  margin-left: 150px;
}
.comment-form-container textarea {
  min-height: 160px;
}
.comment-form-container .msg-notice {
  margin-bottom: 10px;
}

.sidebar-block .tag {
  margin: 0 6px 6px 0;
  padding: 8px 21px;
  border: 2px solid #dfdfdf;
  border-radius: 2px;
  color: #444;
  font-size: 1.3rem;
}
.sidebar-block .tag.active, .sidebar-block .tag:hover {
  border-color: var(--c-theme);
  background-color: var(--c-theme);
}
.sidebar-block .tag.active a, .sidebar-block .tag:hover a {
  color: #fff;
}

.page-about .vs-homepage-slideshow .slide-item {
  min-height: auto;
}
.block-title__classic .page-about .block-title {
  text-align: center;
}
.block-title__classic .page-about .block-title::before {
  content: none;
}
.page-about .block-description {
  text-align: center;
}

.block-title {
  color: #28282b;
  font-family: var(--font-body);
  font-size: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.team-slider .card-wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.wishlist-grid {
  transition: all .35s;
  visibility: hidden;
  opacity: 0;
  max-height: 0;
}
.wishlist-grid .product-card .swatch-group {
  padding-left: 0;
}

.compare-wrapper {
  margin-top: 54px;
  overflow-x: auto;
  overflow-y: hidden;
}

.compare-grid {
  transition: all .35s;
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  border-color: #d3d3d3;
}

.page-list__empty {
  display: none;
}
.page-list__empty.is-visible {
  display: block;
}

.page__block-title {
  margin-bottom: 1.2rem;
  color: #333;
  font-family: var(--font-body);
  font-weight: 400;
  line-height: 1;
  padding-left: 1rem;
  font-size: 2.2rem;
}
.page__block-title::before {
  display: inline-block;
  position: relative;
  bottom: -1px;
  left: -1rem;
  width: .4rem;
  height: 1.4rem;
  content: '';
  background-color: var(--c-theme);
}

#shopify-section-top-bar p {
  line-height:1.17 !important;
}


@media (min-width: 576px) {
  .blog-style1 .article-image {
    width: 250px;
    max-width: 250px;
  }
  .blog-style1 .article-details {
    flex: 1;
    -ms-flex: 1;
    margin-left: 30px;
  }
}
@media (min-width: 768px) {
  .blog-style1 .article-image {
    width: 300px;
    max-width: 300px;
  }
  .blog-style1 .article-details {
    margin-left: 40px;
  }

  #CustomerLoginForm {
    padding-right: 45px;
  }


  .template-contact .medium-12 .vb-form {
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
    max-width: 70%;
  }
}
@media (min-width: 992px) {
  .template-blog .large-9 {
    padding-right: 3.5rem;
  }

  .blog-style1 .article-image {
    width: 450px;
    max-width: 450px;
  }
  .blog-style1 .article-details {
    max-width: 545px;
    margin-left: 50px;
  }

  .contact-column .form-clean .contact-form {
    padding: 70px 40px 30px;
  }

  .contact-row .contact-form {
    padding-right: 80px;
  }
}
@media (max-width: 991px) {
  .sidebar-block .block-title {
    margin-bottom: 18px;
  }




  
}
@media (max-width: 767px) {
  .highlight {
    margin: 20px 0 20px 20px;
    padding: 15px 18px;
  }

  .comment-form-container {
    padding: 36px 42px;
  }
  .comment-form-container label {
    width: 90px;
    min-width: 90px;
    margin-right: 10px;
  }
  .comment-form-container input[type="submit"] {
    margin-left: 100px;
    margin-top: 12px;
  }
}
@media (max-width: 567px) {
  .article {
    font-size: .9rem;
  }

  .contact-row .form-classic .contact-input__container {
    padding: 24px;
  }

  .comment-form > .flex,
  .comment-form label {
    display: block;
    margin-bottom: 10px;
  }
  .comment-form input[type="text"],
  .comment-form input[type="email"],
  .comment-form textarea {
    width: 100%;
  }
  .comment-form input[type="submit"] {
    margin: 0;
  }
}
@media all and (max-width: 480px) {
  #customer_login_guest {
    margin-left: 0;
  }
}








@media(max-width: 991px){
.blog-style3 {
    flex: calc(50% - 30px);
    max-width: calc(50% - 30px);
    width: calc(50% - 30px);
}
  
}



@media(max-width: 767px){
#shopify-section-header-mobile .header {
    padding: 10px 0 5px 0 !important;
}
body:not(.template-index) .breadcrumb {
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    display: none !important;
}
.blog-style3 {
    flex: 100%;
    max-width: 100%;
    width: 100%;
    margin: 0 0 30px;
}
.col-padding .row{
  margin: 0;
}


  
}




